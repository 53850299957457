import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Trans } from "react-i18next";

import Apple from "./AppleStore.png";
import Google from "./PlayStore.png";

export function MobileApp() {
  const { translate } = useTranslation();

  return (
    <section style={{ height: "calc(100vh - var(--sprint1-header-height))" }}>
      <div className="container py-5 h-100">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col-12 col-md-8 col-lg-6 col-xl-5">
            <div className="card shadow-2-strong">
              <div className="card-body p-3">
                <div>
                  <h1 className="fs-4 py-2 fw-bolder">{translate("__downloadMobileApp")}</h1>
                  <div>{translate("__mobileAppDescription")}</div>
                  <h2 className="fs-5 pt-3">{translate("__keyFeatures")}</h2>
                  <ul>
                    <li>
                      <Trans i18nKey="__keyFeature_ConvenientAppointment">
                        <strong></strong>
                      </Trans>
                    </li>
                    <li>
                      <Trans i18nKey="__keyFeature_HealthRecords">
                        <strong></strong>
                      </Trans>
                    </li>
                    <li>
                      <Trans i18nKey="__keyFeature_Telemedicine">
                        <strong></strong>
                      </Trans>
                    </li>
                  </ul>
                  <h2 className="fs-5 pt-3">{translate("__downloadNow")}</h2>

                  <div className="d-flex  justify-content-center gap-2">
                    <a href="https://apps.apple.com/us/app/id1234567890" target="_blank" rel="noreferrer">
                      <img
                        src={Apple}
                        className="img-responsive"
                        style={{ width: "10vw" }}
                        alt="Download on Apple App Store"
                      />
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.mdortho.app.mobile"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={Google}
                        className="img-responsive ms-2"
                        style={{ width: "10vw" }}
                        alt="Download on Google Play Store"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
